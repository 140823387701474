import {LocaleDataModel} from '@core/models/local/locale.model'

const universities: LocaleDataModel = {
  local_unis: 'Қазақстан',
  foreign_unis: 'Басқа елдер',
  list: {
    title: 'ЖОО',
    universities_count: 'Университет саны',
    programs_count: 'Мамандық саны',
    search_hint: 'Аты бойынша іздеу...',
    select_city: 'Қаланы таңдау',
    all_cities: 'Барлық қалалар',
  },
  view: {
    title: 'ЖОО',
    grant_cnt: 'Грант саны',
    min_stat_grade: 'Грантқа мин. ұпай саны',
    description: 'Сипаттама',
    programs: 'Мамандықтар',
    about_university: 'Университет туралы',
    average_price: 'Орташа бағасы',
    status: 'Статус',
    dormitory: 'Жатақхана',
    military_faculty: 'Әскери кафедра',
    get_consultee: 'Кеңес алу',
    contacts: 'Байланыстар',
    website: 'Веб-сайт',
    gallery: 'Фотогалерея',
  },
  foreign: {
    foreign_countries_count: 'Мемлекеттер саны',
    university_count: 'Университеттер',
    view: {
      title: 'ЖОО',
      grant_cnt: 'Грант саны',
      min_stat_grade: 'Грантқа мин. ұпай саны',
      description: 'Сипаттама',
      programs: 'Мамандықтар',
      about_university: 'Университет туралы',
      average_price: 'Орташа бағасы',
      status: 'Статус',
      dormitory: 'Жатақхана',
      military_faculty: 'Әскери кафедра',
      contacts: 'Байланыстар',
      website: 'Веб-сайт',
      gallery: 'Фотогалерея',
      advantage: 'Артықшылықтар',
      requirements: 'Талаптар',
      city_images: 'Қаланың фотосы',
      city_price: 'Тұру бағасы',
      city_price_amount: '{{price}}/айына',
      city_security: 'Қауіпсіздік',
      city_comfort: 'Жайлылық',
      apply: 'ОҚУҒА ТҮСКІМ КЕЛЕДІ',
      apply_form: {
        header: 'Осы университетке түскіңіз келе ме?',
        text: 'Барлық сұрақтарыңызға жауап береміз. Тегін кеңес алу үшін, төмендегі кнопканы басыңыз.',
        hint: 'Өтінім қалдырыңыз және алғашқы <b>тегін</b> кеңес алыңыз!',
        confirm: 'Оқуға түскім келеді',
        reject: 'Кейінрек',
      }
    },
  },
  consultee: {
    msg_header: 'Pахмет!',
    msg_text_foreign: 'Біз сізге міндетті түрде хабарласамыз, қоңырауымызды жіберіп алмаңыз!',
    msg_text_local: 'Жақында университет өкілдері сізге телефон соғады.',
    msg_close: 'Тамаша'
  }
}

export default universities
