import {LocaleDataModel} from '@core/models/local/locale.model'

const settings: LocaleDataModel = {
  edit: 'Изменить',
  save: 'Сохранить',
  full_name: 'ФИО',
  region: 'Регион',
  privacy_policy: 'Пользовательское соглашение',
}

export default settings
