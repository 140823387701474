import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'

import {ApiService} from '@core/services/api/api.service'
import {ListModel} from '@core/models/api/core/list.model'
import {RegionModel} from '@core/models/api/core/region.model'
import {map} from 'rxjs/operators'
import {SKIP_LOADER_SHOWING_PARAM_KEY} from '@core/utils/params'

@Injectable({
  providedIn: 'root'
})
export class RegionsService extends ApiService {

  constructor(
    protected http: HttpClient
  ) {
    super('regions')
  }

  fetch(): Observable<RegionModel[]> {
    const url = this.getUrl()
    const params: any = {page_size: 50, [SKIP_LOADER_SHOWING_PARAM_KEY]: true}

    return this.http.get<ListModel<RegionModel>>(url, {params})
      .pipe(
        map(({results}) => results)
      )
  }

  list(params?: any): Observable<ListModel<RegionModel>> {
    return this.http.get<ListModel<RegionModel>>(this.getUrl(), {params})
  }
}
