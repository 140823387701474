<div class="modal-header">{{options.title | translate}}</div>

<div class="modal-body" [innerHTML]="options.message | translate"></div>

<div *ngIf="options.showOffer" class='modal-body'>

  <embed
    class="pdf"
    src="https://app.joo.kz/static/public_offer_rus.pdf#view=FitH&scrollbar=0&toolbar=0&statusbar=0&messages=0&navpanes=0"
    type="application/pdf"
  />

  <div class="offer-confirm">

    <div
      class="checkbox"
      [class.selected]="offerChecked"
      (click)="toggleOffer()"
    >
      <i class="fal fa-check"></i>
    </div>

    <label class="offer_text">
      {{'edu.screening_test.confirm_offer' | translate}}
    </label>

  </div>

</div>

<div class="modal-footer justify-content-end">

  <button
    *ngIf="options.cancelText"
    class="btn btn-sm btn-outline-secondary mr-2"
    (click)="onClose()"
  >
    {{options.cancelText | translate}}
  </button>

  <button
    class="btn btn-sm btn-orange"
    [disabled]="options.showOffer && !offerChecked"
    (click)="onConfirm()"
  >
    {{options.confirmText | translate}}
  </button>

</div>
