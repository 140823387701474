import {LocaleDataModel} from '@core/models/local/locale.model'

const settings: LocaleDataModel = {
  edit: 'Өзгерту',
  save: 'Сақтау',
  full_name: 'Аты-жөні',
  region: 'Аймақ',
  privacy_policy: 'Пайдаланушы келісімі',
}

export default settings
