import {environment} from '@env'

export class ApiService {

  constructor(
    protected uri: string
  ) {
  }

  protected getUrl(branch?: string | number): string {
    let url = `${environment.api_v1}/${this.uri}`

    if (branch) {
      url += `/${branch}`
    }

    return `${url}/`
  }
}
