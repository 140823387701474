import {LocaleDataModel} from '@core/models/local/locale.model'

const locale: LocaleDataModel = {
  page_title: 'Тренажер',
  theme_list: 'Тақырыптар',
  empty_theme_list: 'Бұл пән бойынша әлі сұрақтар жоқ. Біздің ұжым олармен қазір жұмыс істеуде.',
  test_page: 'Сынақ парақшасы',
  subject_list: 'Сабақтар тізімі',
  subtheme_progress: 'Прогресс: {{progress}}',
  subtheme_progress_done: 'Орындалды',
  subtheme_completed: 'Тест аяқталды!',
  use_solution: 'Кеңесті қолдану',
  solution_header: 'Кеңес #{{id}}',
  submit_question: 'Жауапты тексеру',
  next_question: 'Келесі сұрақ',
  select_answer: 'Жауапты таңдаңыз',
  restart: {
    label: 'Қайтадан бастау',
    confirm: 'Қайтадан бастағыңыз келе ме?',
    cancel: 'Бастарту'
  },
  main: {
    title: 'Тренажер',
    banner: {
      title: 'Тесттерді тапсырып біліміңізді бекітіңіз',
      detail: 'Әртүрлі деңгейдегі сынақтар',
      btn: 'Толығырақ',
    },
    info_modal: {
      title: '«Тренажер»<br>деген не?',
      message: 'Тренажер - белгілі бір пәндер мен тақырыптар бойынша біліміңізді шыңдай алатын орын. 5 негізгі пән бойынша кез келген тақырып бойынша тест тапсырмаларын орындап, өтілген материалды бекітіңіз. Іске сәт!',
    },
    results: {
      title: 'Апта нәтижесі',
      empty: 'Деректер жоқ',
      today_correct_answers: 'Бүгін: {{count}} дұрыс жауап',
    },
    subjects: {
      title: 'Сабақтар тізімі',
    }
  },
  filter: {
    btn_text: 'Тақырыпты таңдаңыз',
    header: 'Тақырып таңдау',
    footer: {
      cancel: 'Болдырмау',
      submit: 'Тақырыпты таңдау'
    }
  }
}

export default locale
