import {Component, OnInit} from '@angular/core'
import {DialogConfirmModel} from '@core/models/local/dialog-confirm.model'
import {BsModalRef} from 'ngx-bootstrap/modal'

@Component({
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {

  options: DialogConfirmModel

  top: number = 0
  bottom: number = 0

  constructor(
    private bsModalRef: BsModalRef,
  ) {
  }

  ngOnInit(): void {
    if (!this.options.confirmText) {
      this.options.confirmText = 'OK'
    }

    let modalContainer = document.getElementsByTagName('modal-container')[0]
    if (this.options.blur) {
      modalContainer.classList.add('modal-blur')
    }

    switch (this.options.position) {
      case 'center':
        this.bsModalRef.setClass('modal-dialog-centered')
        break
    }
  }

  onClose(): void {
    this.bsModalRef.hide()
  }

  onConfirm(): void {
    this.onClose()

    if (this.options.onConfirm) {
      this.options.onConfirm()
    }
  }
}
