import {LocaleDataModel} from '@core/models/local/locale.model'

const groups: LocaleDataModel = {
  list: {
    title: 'Профессии',
    programs: 'Специальности',
    employment_rate: 'Трудоустройство',
    probability_count: 'Подсчет вероятности',
    reset_subjects: 'Очистить предметы',
    first_subject: 'Первый предмет',
    second_subject: 'Второй предмет',
    rural: 'Сельская квота',
    your_probable_grade: 'Ваш примерный балл ЕНТ',
    see_chances: 'Смотреть шансы',
  },
  view: {
    title: 'Профессии',
    subjects: 'Предметы',
    description: 'Описание',
    universities: 'Университеты',
    edu: 'Обучение',
    salary: 'Зарплата',
    employment_rate: 'Спрос',
    grant_cnt: 'Кол. грантов',
    score_table: 'Проходной балл',
    score_table_year: 'Год',
    score_table_general: 'Общий',
    score_table_rural: 'Сельский',
  },
  search_choices: {
    title: {
      1: 'Профессии',
      2: 'Выборы',
    },
    by_groups: 'По специальностям',
    by_areas: 'По сферам',
    select_group: 'Выбрать специальность',
    select_area: 'Выбрать сферу',
    see_result: 'Смотреть результат',
    empty_groups_list: 'Нет соответствующих специальностей',
    empty_areas_list: 'Нет соответствующих сфер',
  },
  search_result: {
    title: {
      1: 'Профессии',
      2: 'Вероятность',
    },
    total_probability: 'Общая вероятность на получение гос. гранта',
    select_another: 'Выбрать другое',
    not_selected: 'Не выбрано',
    close: 'Закрыть',
    select_group: 'Выбрать специальность',
    select_university: 'Выберите университет',
    select_grant_type: 'Выберите тип гранта',
  },
}

export default groups
