<div class="modal-body" *ngIf="options">

  <div class="title" [innerHTML]="options.title | translate: options.titleParams"></div>

  <div class="message" *ngIf="options.message" [translate]="options.message" [translateParams]="options.messageParams"></div>

  <div class="btn btn-orange" [translate]="options.confirmText" (click)="onConfirm()"></div>

  <div class="cancel-btn cursor-pointer" *ngIf="options.cancelText" [translate]="options.cancelText" (click)="onClose()"></div>

</div>
