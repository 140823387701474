import {LocaleDataModel} from '@core/models/local/locale.model'

const sidebar: LocaleDataModel = {
  main: 'Лента',
  edu: 'Дайындық',
  universities: 'ЖОО',
  professions: 'Мамандықтар',
  profile: 'Профиль',
  logout: 'Шығу',
}

export default sidebar
