import {LocaleDataModel} from '@core/models/local/locale.model'

const general: LocaleDataModel = {
  back: 'Назад',
  next: 'Далее',
  cancel: 'Отмена',
  save: 'Сохранить',
  subtitle_2: 'Скачайте приложение',
  title_2: 'Если вы хотите пользоваться сервисами Joo.kz с мобильного телефона, лучше скачайте наше официальное приложение!',
  title_3: 'Этот сайт можно только просмотреть через компьютер!',
  days: 'дней',
  hours: 'час',
  minutes: 'мин',
  seconds: 'сек',
  today: 'Сегодня',
  back_to_main: 'На главную',
  empty_list: 'Список пуст',
}

export default general
