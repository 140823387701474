import {LocaleDataModel} from '@core/models/local/locale.model'

const sidebar: LocaleDataModel = {
  main: 'Лента',
  edu: 'Обучение',
  universities: 'ВУЗы',
  professions: 'Профессии',
  profile: 'Профиль',
  logout: 'Выйти',
}

export default sidebar
