import {LocaleDataModel} from '@core/models/local/locale.model'

const notifications: LocaleDataModel = {
  title: 'Уведомления',
  open_link: 'Перейти',
  poll: {
    heading: 'Опросы',
    open_poll: 'Пройти опрос',
    send_poll: 'Отправить',
    sended: 'Отзыв отправлен',
    answer_text_placeholder: 'Введите текст...'
  }
}

export default notifications
