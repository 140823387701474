import {LocaleDataModel} from '@core/models/local/locale.model'

const locale: LocaleDataModel = {
  title: 'Страница теста',
  heading: 'Текст вопроса',
  quit_test: 'Выйти с теста',
  subject_list: 'Список предметов',
  question_index: '{{index}} из {{count}}',
  prev_question: 'Предыдущий вопрос',
  next_question: 'Следующий вопрос',
  finish: 'Завершить тест',
  submit_ans: 'Ответить',
  use_hint: 'Использовать подсказку',
  hint_no: 'Подсказка #{{no}}',
  continue: 'Продолжить',
  finished: 'Завершен',
  start: 'Начать',

  did_you_find_errors: 'Нашли ошибку?',
  support_request: {
    title: 'Выберите тип ошибки',
    description_placeholder: 'Если вашей ошибки нет в списке, напишите подробнее здесь...',
    cancel: 'Отмена',
    submit: 'Отправить',
    success_modal: {
      title: 'Спасибо за помощь!',
      subtitle: 'Обнаружив наши ошибки, вы очень поможете нам в нашем развитии!',
      close: 'Закрыть',
    }
  },
}

export default locale
