import {LocaleDataModel} from '@core/models/local/locale.model'

const locale: LocaleDataModel = {
  title: 'Пәндер',
  lesson_cnt: 'Сабақтар саны: {{cnt}}',
  lesson_finished_cnt: 'Аяқталды: {{cnt}}',
  expire: 'Пән төленбеген'
}

export default locale
