import {Injectable} from '@angular/core'
import {Action, Selector, State, StateContext, Store} from '@ngxs/store'
import {TranslateService} from '@ngx-translate/core'
import * as moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/kk'

import {FetchLocalesBasedStatic, Init, UpdateLang, UpdateStatic} from './actions'

import localeKk from '@assets/i18n/kk'
import localeRu from '@assets/i18n/ru'
import {AuthState} from '@core/states/auth/auth.state'
import {LangType} from '@core/types/api/lang.type'
import {DictionariesService} from '@core/services/api/dictionaries.service'
import {DictionariesModel} from '@core/models/api/core/dictionaries.model'
import {SubjectsService} from '@core/services/api/subjects.service'
import {SubjectLocalModel} from '@core/models/local/subject.local.model'
import {AreasService} from '@core/services/api/areas.service'
import {PostsService} from '@core/services/api/posts.service'
import {RegionsService} from '@core/services/api/regions.service'
import {RegionModel} from '@core/models/api/core/region.model'

interface AppStateModel {
  lang: LangType;
  dictionaries: DictionariesModel;
  mainSubjects: SubjectLocalModel[];
  areas: any[];
  rubrics: any[];
  regions: RegionModel[];
}

const defaults: AppStateModel = {
  lang: null,
  dictionaries: null,
  mainSubjects: [],
  areas: [],
  rubrics: [],
  regions: [],
}

@State<AppStateModel>({
  name: 'app',
  defaults
})
@Injectable()
export class AppState {

  @Selector()
  static lang({lang}: AppStateModel): LangType {
    return lang
  }

  @Selector()
  static dictionaries({dictionaries}: AppStateModel): DictionariesModel {
    return dictionaries
  }

  @Selector()
  static mainSubjects({mainSubjects}: AppStateModel): SubjectLocalModel[] {
    return mainSubjects
  }

  @Selector()
  static areas({areas}: AppStateModel): any[] {
    return areas
  }

  @Selector()
  static rubrics({rubrics}: AppStateModel): any[] {
    return rubrics
  }

  @Selector()
  static regions({regions}: AppStateModel): RegionModel[] {
    return regions
  }

  constructor(
    private store: Store,
    private translateService: TranslateService,
    private dictionariesService: DictionariesService,
    private subjectsService: SubjectsService,
    private areasService: AreasService,
    private postsService: PostsService,
    private regionsService: RegionsService,
  ) {
    moment.updateLocale('ru', {
      monthsShort: 'янв_фев_март_апр_май_июнь_июль_авг_сен_окт_ноя_дек'.split('_')
    })
  }

  @Action(Init)
  Init({getState, patchState}: StateContext<AppStateModel>) {
    let lang = getState().lang
    if (!lang) {
      lang = 'kk'
    }

    patchState({lang})
    moment.locale(lang)

    this.translateService.addLangs(['kk', 'ru'])
    this.translateService.setDefaultLang(lang)
    this.translateService.use(lang);

    [localeKk, localeRu].forEach(({lang, data}) => {
      this.translateService.setTranslation(lang, data, true)
    })
  }

  @Action(UpdateLang)
  UpdateLang({getState, patchState}: StateContext<AppStateModel>, {lang}: UpdateLang) {
    if (lang !== getState().lang) {
      moment.locale(lang)
      patchState({lang})
      this.translateService.use(lang)
      this.store.dispatch(UpdateStatic)
    }
  }

  @Action(UpdateStatic)
  UpdateStatic({patchState}: StateContext<AppStateModel>) {
    if (this.store.selectSnapshot(AuthState.token)) {
      return Promise.all([
        this.dictionariesService.fetch().toPromise(),
        this.subjectsService.fetchFromMain().toPromise(),
        this.areasService.fetch().toPromise(),
        this.postsService.fetchRubrics().toPromise(),
      ]).then(result => {
        const [dictionaries, mainSubjects, areas, rubrics] = result
        patchState({
          dictionaries,
          mainSubjects,
          areas,
          rubrics
        })
      })
    }

    return this.dictionariesService.fetch()
      .toPromise()
      .then(dictionaries => {
        patchState({dictionaries})
      })
  }

  @Action(FetchLocalesBasedStatic)
  FetchLocalesBasedStatic({patchState}: StateContext<AppStateModel>) {
    return Promise.all([
      this.regionsService.fetch().toPromise(),
    ]).then(result => {
      const [regions] = result
      patchState({
        regions
      })
    })
  }
}
