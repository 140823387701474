import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {Store} from '@ngxs/store'
import {Observable} from 'rxjs'

import {AuthState} from '@core/states/auth/auth.state'

@Injectable()
export class RedirectGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const token = this.store.selectSnapshot(AuthState.token)

    if (token) {
      console.log('[RedirectGuard] Has token, redirecting app.')
      this.router.navigate(['/'])
      return false
    }

    console.log('[RedirectGuard] No token, entering auth.')
    return true
  }
}
