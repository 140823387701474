import {LocaleDataModel} from '@core/models/local/locale.model'

const auth: LocaleDataModel = {
  login: {
    title: 'Вход',
    phone: 'Телефон',
    password: 'Пароль',
    sign_in: 'Войти',
    forget_password: 'Забыли пароль?',
    register: 'Зарегистрироваться',
  },
  register: {
    title: 'Регистрация',
    phone: 'Телефон',
    full_name: 'ФИО',
    region: 'Регион',
    password: 'Придумайте пароль',
    confirm_password: 'Подтвердите пароль',
    create_account: 'Создать аккаунт',
    passwords_must_be_equal: 'Пароли должны совпадать.',
  },
  code: {
    title: 'Подтвердите номер',
    resend_code_after: 'Повторно код можно отправить через',
    resend_code: 'Отправить код повторно',
  },
  password_reset: {
    title: 'Сброс пароля',
    password: 'Придумайте пароль',
    confirm_password: 'Подтвердите пароль',
    sign_in: 'Войти',
  },
}

export default auth
