import {LocaleDataModel} from '@core/models/local/locale.model'

const locale: LocaleDataModel = {
  title: 'Сабақтар',
  today: 'Бүгін',
  lesson_count: 'Сабақтар: {{length}}',
  show_all: 'Барлығы',
  no_lessons: 'Бүгін сабақ жоқ',
  no_record_url: 'Тікелей эфир әлі қолжетімді емес',
  empty_list: 'Тізім бос',
  lesson_broadcast: 'Сабақ трансляциясы',
  all_schedule: 'Барлық кестелер',
  lesson_order: 'Тема',
  lesson_order_name: 'Сабақ №{{order}}. {{name}}',
  lesson_starts_at: '{{time}}-да басталады',
  lesson_started_at: '{{time}}-да басталды',
  lesson_page: 'Сабақ парақшасы',
  lesson_empty: 'Бұл бөлімге қосымша материалдар али қосылған жоқ, біздің команда бұл бағытта жұмыс жасауда.',
  lesson_finished: 'Аяқталды',
  lesson_started: 'Сабақ басталып кетті! Кураторыңыздың ортақ чатқа жіберген сілтеме арқылы сабаққа өтіңіз!',
  open_broadcast_url: 'Трансляцияға өту',
  errors: {
    lesson_not_started: 'Сабақ әлі басталған жоқ',
    lesson_not_started_msg: 'Сабақтың басталуы: {{time}}'
  },
  tabs: {
    description: 'Сипаттама',
    material: 'Материалдар',
    broadcast: 'Тікелей эфир',
    materials: 'Қосымша материалдар'
  },
  homework: {
    title: 'Үй жұмысы',
  },
  description: {
    read_more: 'Барлығын оқу',
    read_less: 'Жасыру'
  },
  broadcast: {
    comment_textarea: 'Комментарии енгізіңіз'
  },
  lock: {
    header: 'Пән төленбеген',
    content: `Келесі айдың оқу ақысы <span class="nowrap c-red">- төленбеген.</span> Толық ақпарат алу үшін <b class="nowrap">+7 747 650 37 22</b> нөміріне хабарласыңыз.`
  }
}

export default locale
