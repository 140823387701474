const api_host = 'https://app.dev.joo.kz'
const api_v1 = `${api_host}/api/v1`
const api_v2 = `${api_host}/api/v2`

export const environment = {
  production: false,
  api_host,
  api_v1,
  api_v2,
}
