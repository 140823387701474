export const SKIP_ERROR_HANDLING_PARAMS: any = {
  skip_error_handling: true
}

export const SKIP_TOKEN_REFRESH_PARAMS: any = {
  skip_token_refresh_handling: true
}

export const MEDIA_HEAD_REQ: any = {
  media_head_req: true
}

export const SKIP_LOADER_SHOWING_PARAM_KEY = 'skip_loader_showing'
export const SKIP_LOADER_SHOWING_PARAMS = {[SKIP_LOADER_SHOWING_PARAM_KEY]: 'true'}
