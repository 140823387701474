import {LocaleModel} from '@core/models/local/locale.model'

import general from '@assets/i18n/general/ru'
import notifications from '@assets/i18n/notifications/ru'
import errors from '@assets/i18n/errors/ru'
import auth from '@assets/i18n/auth/ru'
import posts from '@assets/i18n/posts/ru'
import comments from '@assets/i18n/comments/ru'
import sidebar from '@assets/i18n/sidebar/ru'
import universities from '@assets/i18n/universities/ru'
import groups from '@assets/i18n/groups/ru'
import settings from '@assets/i18n/settings/ru'
import edu from '@assets/i18n/edu/ru'
import account_management from '@assets/i18n/account_management/ru'

const localeRu: LocaleModel = {
  lang: 'ru',
  data: {
    kk: 'Казахский',
    ru: 'Русский',
    general,
    notifications,
    errors,
    auth,
    posts,
    comments,
    sidebar,
    universities,
    groups,
    settings,
    edu,
    account_management,
  }
}

export default localeRu
