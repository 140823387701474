import {Injectable} from '@angular/core'
import {Action, Selector, State, StateContext, Store} from '@ngxs/store'

import {
  FirstStepSubmit,
  SecondStepSubmit,
  ThirdStepSubmit,
  UpdateSearchArea,
  UpdateSearchGroup,
  UpdateSearchParams
} from '@core/states/search/actions'
import {SearchService} from '@core/services/api/search.service'
import {Navigate} from '@ngxs/router-plugin'
import {AppState} from '@core/states/app/app.state'
import {SearchStep2GroupModel} from '@core/models/api/core/search-step2-group.model'
import {SearchAreaModel} from '@core/models/api/core/search-area.model'
import {PopLoaderQueue, PushLoaderQueue} from '@core/states/loader/actions'
import {AreasService} from '@core/services/api/areas.service'
import {switchMap, tap} from 'rxjs/operators'
import {AreaModel} from '@core/models/api/core/area.model'
import {SearchOptionModel} from '@core/models/api/core/search-option.model'
import {getGrantIds} from '@core/utils/dictionaries'
import {SPECIFIC_TARGET_GRANT_UNIVERSITY_IDS} from '@core/utils/defaults'
import {DialogService} from '@core/services/ui/dialog.service'

interface ChoiceOrderMap<T> {
  0: T;
  1: T;
  2: T;
  3: T;
}

export interface SearchStateModel {
  id?: number;
  subject_1?: number;
  subject_2?: number;
  is_rural?: boolean;
  grade?: number;
  choicesNum?: number;
  groupOrderMap?: ChoiceOrderMap<SearchStep2GroupModel>;
  areaOrderMap?: ChoiceOrderMap<SearchAreaModel>;
  total?: number;
  options?: SearchOptionModel[];
}

const defaults: SearchStateModel = {
  id: null,
  subject_1: null,
  subject_2: null,
  is_rural: false,
  grade: 50,
  choicesNum: null,
  groupOrderMap: {0: null, 1: null, 2: null, 3: null},
  areaOrderMap: {0: null, 1: null, 2: null, 3: null},
  total: null,
  options: null
}

@State<SearchStateModel>({
  name: 'search',
  defaults
})
@Injectable()
export class SearchState {

  @Selector()
  static id({id}: SearchStateModel): number {
    return id
  }

  @Selector()
  static choicesNum({choicesNum}: SearchStateModel): number {
    return choicesNum
  }

  @Selector()
  static groupsOrdered({groupOrderMap}: SearchStateModel): SearchStep2GroupModel[] {
    return Object.keys(groupOrderMap).map(order => groupOrderMap[order])
  }

  @Selector()
  static hasAtLeastSingleGroup({groupOrderMap}: SearchStateModel): boolean {
    return Object.keys(groupOrderMap).filter(order => groupOrderMap[order] !== null).length > 0
  }

  @Selector()
  static areasOrdered({areaOrderMap}: SearchStateModel): SearchAreaModel[] {
    return Object.keys(areaOrderMap).map(order => areaOrderMap[order])
  }

  @Selector()
  static hasAtLeastSingleArea({areaOrderMap}: SearchStateModel): boolean {
    return Object.keys(areaOrderMap).filter(order => areaOrderMap[order] !== null).length > 0
  }

  @Selector()
  static options({options}: SearchStateModel): SearchOptionModel[] {
    return [...Array(4).keys()].map(index => options[index] ? options[index] : null)
  }

  @Selector()
  static total({total}: SearchStateModel): number {
    return total
  }

  constructor(
    private searchService: SearchService,
    private areasService: AreasService,
    private store: Store,
    private dialogService: DialogService,
  ) {
  }

  @Action(UpdateSearchParams)
  UpdateSearchParams({getState, patchState}: StateContext<SearchStateModel>, {params}: UpdateSearchParams) {
    return patchState({...getState(), ...params})
  }

  @Action(FirstStepSubmit)
  FirstStepSubmit({getState}: StateContext<SearchStateModel>) {
    const {subject_1, subject_2, is_rural, grade} = getState()

    return this.searchService.firstStepSubmit({subject_1, subject_2, is_rural, grade})
      .pipe(
        switchMap(({id}) => {
          const subjects = this.store.selectSnapshot(AppState.mainSubjects)
          const firstSubject = subjects.find(subject => subject.id === subject_1)
          const {choicesNum} = firstSubject.pairs.find(pair => pair.subjectId === subject_2)

          return this.store.dispatch([
            new UpdateSearchParams({
              id,
              choicesNum,
              groupOrderMap: {...defaults.groupOrderMap},
              areaOrderMap: {...defaults.areaOrderMap},
              options: null,
              total: null,
            }),
            new Navigate(['/main/groups/search/search-choices'])
          ])
        })
      )
  }

  @Action(UpdateSearchGroup)
  UpdateSearchGroup({getState, patchState}: StateContext<SearchStateModel>, {index, searchGroup}: UpdateSearchGroup) {
    const {groupOrderMap} = getState()
    groupOrderMap[index] = searchGroup
    return patchState({groupOrderMap})
  }

  @Action(UpdateSearchArea)
  UpdateSearchArea({getState, patchState}: StateContext<SearchStateModel>, {index, searchArea}: UpdateSearchArea) {
    const {areaOrderMap} = getState()

    if (searchArea) {
      return this.areasService.get(searchArea.area as number)
        .pipe(
          tap(area => {
            searchArea.area = area
            areaOrderMap[index] = searchArea
            patchState({areaOrderMap})
          })
        )
    }

    areaOrderMap[index] = null
    return patchState({areaOrderMap})
  }

  @Action(SecondStepSubmit)
  SecondStepSubmit({getState, patchState}: StateContext<SearchStateModel>, {type}: SecondStepSubmit) {
    const uuid = Math.random().toString()

    this.store.dispatch(new PushLoaderQueue(uuid))

    setTimeout(() => {
      this.store.dispatch(new PopLoaderQueue(uuid))

      let payload

      switch (type) {
        case 'by_groups':
          const groupsOrdered = this.store.selectSnapshot(SearchState.groupsOrdered).filter(group => group)
          payload = {
            search_id: getState().id,
            group_ids: groupsOrdered.map(({group}) => group.id),
            area_ids: groupsOrdered.map(({group}) => group.area),
          }
          break
        case 'by_areas':
          payload = {
            search_id: getState().id,
            area_ids: this.store.selectSnapshot(SearchState.areasOrdered)
              .filter(searchArea => searchArea)
              .map(searchArea => {
                const area = searchArea.area as AreaModel
                return area.id
              })
          }
          break
      }

      this.searchService.secondStepSubmit(type, payload)
        .toPromise()
        .then(response => {
          this.store.dispatch([
            new UpdateSearchParams(response),
            new Navigate(['/main/groups/search/result'], undefined, {replaceUrl: true})
          ])
        })
    }, 2000)
  }

  @Action(ThirdStepSubmit)
  ThirdStepSubmit({getState, patchState}: StateContext<SearchStateModel>, action: ThirdStepSubmit) {
    const {selectedGroup, selectedUniversity, selectedGrantId, selectedIndex} = action

    const {options} = getState()

    const dictionaries = this.store.selectSnapshot(AppState.dictionaries)
    const generalGrants = getGrantIds(dictionaries, 'GENERAL')
    const targetGrants = getGrantIds(dictionaries, 'TARGET')
    const serpinGrants = getGrantIds(dictionaries, 'SERPIN')

    const specificTargetGrantSelected = targetGrants.includes(selectedGrantId) &&
      SPECIFIC_TARGET_GRANT_UNIVERSITY_IDS.includes(selectedUniversity.id)

    if (specificTargetGrantSelected) {
      let nonTargetGrantsExistBefore = false

      for (let i = 0; i < selectedIndex; i++) {
        if (!(targetGrants.includes(options[i].grant_type) && options[i].university.id === selectedUniversity.id)) {
          nonTargetGrantsExistBefore = true
          break
        }
      }

      if (nonTargetGrantsExistBefore) {
        this.dialogService.error({
          message: 'Выбранные университеты с целевыми грантами должны находиться в начале списка.'
        })
        return
      }

      let targetGrantDifferentUniversity = false

      const otherTargetGrantUniversityIds = options
        .filter((editingGroup, index) => {
          return index !== selectedIndex
            && targetGrants.includes(editingGroup.grant_type)
            && editingGroup.university.id !== selectedUniversity.id
            && SPECIFIC_TARGET_GRANT_UNIVERSITY_IDS.includes(editingGroup.university.id)
        })
        .map(({university}) => university.id)

      if (otherTargetGrantUniversityIds.length > 0) {
        targetGrantDifferentUniversity = true
      }

      if (targetGrantDifferentUniversity) {
        this.dialogService.error({
          message: 'Невозможно выбрать некоторые целевые гранты с разными университетами'
        })
        return
      }

      const foundSpecificTargetGrantUniversityGroup = options
        .find((editingGroup, index) => {
          return index !== selectedIndex
            && editingGroup.group.id === selectedGroup.id
            && targetGrants.includes(editingGroup.grant_type)
            && editingGroup.university.id === selectedUniversity.id
        })

      if (foundSpecificTargetGrantUniversityGroup) {
        this.dialogService.error({
          message: 'Невозможно выбрать целевой грант с одинаковыми группами образовательных программ для данного университета'
        })
        return
      }
    } else {
      let targetGrantsExistAfter = false

      for (let i = selectedIndex + 1; i < options.length; i++) {
        if (targetGrants.includes(options[i].grant_type)
          && SPECIFIC_TARGET_GRANT_UNIVERSITY_IDS.includes(options[i].university.id)) {
          targetGrantsExistAfter = true
          break
        }
      }

      if (targetGrantsExistAfter) {
        this.dialogService.error({
          message: 'Выбранные университеты с целевыми грантами должны находиться в начале списка'
        })
        return
      }
    }

    let probability = 0

    if (generalGrants.includes(selectedGrantId)) {
      probability = selectedUniversity.general_probability
    } else if (targetGrants.includes(selectedGrantId)) {
      probability = selectedUniversity.target_probability
    } else if (serpinGrants.includes(selectedGrantId)) {
      probability = selectedUniversity.serpin_probability
    }

    options[selectedIndex] = {
      grant_type: selectedGrantId,
      group: selectedGroup,
      university: selectedUniversity,
      probability,
    }

    const combinedProbabilities = []
    const editingGroupsProbabilities = options.map(editingGroup => editingGroup.probability)

    editingGroupsProbabilities.forEach((p, i) => {
      if (i === 0) {
        combinedProbabilities.push(p)
      } else {
        combinedProbabilities.push(
          combinedProbabilities[i - 1] + p - combinedProbabilities[i - 1] * p
        )
      }
    })

    this.store.dispatch(new UpdateSearchParams({
      options: [...options],
      total: null
    }))

    setTimeout(() => {
      this.store.dispatch(new UpdateSearchParams({
        total: combinedProbabilities.pop()
      }))
    }, 2000)
  }
}
