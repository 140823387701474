import {SearchStateModel} from '@core/states/search/search.state'
import {SearchStep2GroupModel} from '@core/models/api/core/search-step2-group.model'
import {SearchAreaModel} from '@core/models/api/core/search-area.model'
import {SearchStep3UniversityModel} from '@core/models/api/core/search-step3-university.model'
import {GroupModel} from '@core/models/api/core/group.model'

export class UpdateSearchParams {
  static readonly type = '[Search] UpdateSearchParams'

  constructor(public params: SearchStateModel) {
  }
}

export class FirstStepSubmit {
  static readonly type = '[Search] FirstStepSubmit'
}

export class UpdateSearchGroup {
  static readonly type = '[Search] UpdateSearchGroup'

  constructor(public index: number, public searchGroup: SearchStep2GroupModel) {
  }
}

export class UpdateSearchArea {
  static readonly type = '[Search] UpdateSearchArea'

  constructor(public index: number, public searchArea: SearchAreaModel) {
  }
}

export class SecondStepSubmit {
  static readonly type = '[Search] SecondStepSubmit'

  constructor(public type: 'by_groups' | 'by_areas') {
  }
}

export class ThirdStepSubmit {
  static readonly type = '[Search] ThirdStepSubmit'

  constructor(
    public selectedGroup: GroupModel,
    public selectedUniversity: SearchStep3UniversityModel,
    public selectedGrantId: number,
    public selectedIndex: number,
  ) {
  }
}
