import {LocaleDataModel} from '@core/models/local/locale.model'

const errors: LocaleDataModel = {
  title: 'Қате',
  server_error: 'Сервер қатесі пайда болды. Көмек алу үшін жүйелік әкімшіге хабарласыңыз.',
  not_found: 'Ресурс табылмады.',
  mathjax_load_error: 'Математикалық формулаларды көрсету үшін компоненттерді жүктеу кезінде қате пайда болды. Бетті қайта жүктеп көріңіз.',
  unknown_error: 'Белгісіз қате',
  warning: 'Назар аударыңыз',
}

export default errors
