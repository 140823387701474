import {LocaleDataModel} from '@core/models/local/locale.model'
import course_purchase from './course-purchase/ru'
import no_course from './no-course/ru'
import lesson from './lesson/ru'
import homework from './homework/ru'
import sections from './sections/ru'
import subjects from './subjects/ru'
import trainer from './trainer/ru'
import weekly_test from './weekly-test/ru'
import trial_test from './trial-test/ru'
import support from './support/ru'

const locale: LocaleDataModel = {
  title: 'Обучение',
  course_purchase,
  no_course,
  lesson,
  homework,
  sections,
  subjects,
  trainer,
  weekly_test,
  trial_test,
  support,
}

export default locale
