import {LocaleDataModel} from '@core/models/local/locale.model'

const errors: LocaleDataModel = {
  title: 'Ошибка',
  server_error: 'Возникла ошибка сервера. Пожалуйста, обратитесь за помощью к системному администратору.',
  not_found: 'Ресурс не найден.',
  mathjax_load_error: 'Возникла ошибка при загрузке компонентов для отображения математических формул. Пожалуйста, перезагрузите страницу.',
  unknown_error: 'Неизвестная ошибка',
  warning: 'Внимание',
}

export default errors
