import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {shareReplay} from 'rxjs/operators'
import {ApiService} from '@core/services/api/api.service'
import {SKIP_ERROR_HANDLING_PARAMS, SKIP_LOADER_SHOWING_PARAMS, SKIP_TOKEN_REFRESH_PARAMS} from '@core/utils/params'
import {TokenModel} from '@core/models/api/core/token.model'

import {RegisterStateModel} from '@features/auth/register/register.state'
import {PasswordResetStateModel} from '@features/auth/password-reset/password-reset.state'
import {CountryCodeModel} from '@core/models/api/core/country-code.model'

@Injectable({
  providedIn: 'root'
})
export class IdentityService extends ApiService {

  countries$: Observable<CountryCodeModel[]> = this.http
    .get<CountryCodeModel[]>(this.getUrl('countries'), {params: SKIP_LOADER_SHOWING_PARAMS})
    .pipe(shareReplay(1))

  constructor(
    protected http: HttpClient,
  ) {
    super('auth')
  }

  login(payload: any): Observable<TokenModel> {
    const url = this.getUrl('token')
    return this.http.post<TokenModel>(url, payload, {params: SKIP_TOKEN_REFRESH_PARAMS})
  }

  refreshToken(refresh: string): Observable<any> {
    const url = this.getUrl('token/refresh')
    return this.http.post<any>(url, {refresh}, {params: SKIP_ERROR_HANDLING_PARAMS})
  }

  logout(): Observable<any> {
    const url = this.getUrl('logout')
    return this.http.post<any>(url, null, {params: SKIP_ERROR_HANDLING_PARAMS})
  }

  registerRequest(payload: any): Observable<RegisterStateModel> {
    const url = this.getUrl('signup/request')
    return this.http.post<RegisterStateModel>(url, payload)
  }

  register(payload: RegisterStateModel): Observable<TokenModel> {
    const url = this.getUrl('signup')
    return this.http.post<TokenModel>(url, payload)
  }

  passwordResetRequest(payload: any): Observable<PasswordResetStateModel> {
    const url = this.getUrl('reset_password/request')
    return this.http.post<PasswordResetStateModel>(url, payload)
  }

  passwordReset(payload: any): Observable<any> {
    const url = this.getUrl('reset_password')
    return this.http.post<any>(url, payload)
  }

  deleteAccount(tokens: TokenModel, payload: any): Observable<void> {
    const url = this.getUrl('account/removal')
    const params = SKIP_TOKEN_REFRESH_PARAMS
    const headers: any = {Authorization: `Bearer ${tokens.access}`}

    return this.http.post<void>(url, payload, {params, headers})
  }
}
