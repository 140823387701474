import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'

import {ApiService} from '@core/services/api/api.service'
import {SearchStateModel} from '@core/states/search/search.state'
import {SearchStep2GroupModel} from '@core/models/api/core/search-step2-group.model'
import {SearchType} from '@core/types/local/search.type'
import {SearchStep3GroupModel} from '@core/models/api/core/search-step3-group.model'
import {SearchStep3UniversityModel} from '@core/models/api/core/search-step3-university.model'
import {map} from 'rxjs/operators'
import {SPECIFIC_TARGET_GRANT_UNIVERSITY_IDS} from '@core/utils/defaults'

@Injectable({
  providedIn: 'root'
})
export class SearchService extends ApiService {

  constructor(
    protected http: HttpClient,
  ) {
    super('search_v2')
  }

  firstStepSubmit(payload: SearchStateModel): Observable<SearchStateModel> {
    return this.http.post<SearchStateModel>(this.getUrl('1/submit'), payload)
  }

  secondStepGroupSearch(payload: any): Observable<SearchStep2GroupModel[]> {
    return this.http.post<SearchStep2GroupModel[]>(this.getUrl('2/by_groups/search'), payload)
  }

  secondStepSearch<T>(type: SearchType, payload: any): Observable<T[]> {
    return this.http.post<T[]>(this.getUrl(`2/${type}/search`), payload)
  }

  secondStepSubmit(uri: string, payload: any): Observable<SearchStateModel> {
    return this.http.post<SearchStateModel>(this.getUrl(`2/${uri}/submit`), payload)
  }

  thirdStepGroupSearch(payload: any): Observable<SearchStep3GroupModel[]> {
    return this.http.post<SearchStep3GroupModel[]>(this.getUrl(`3/groups/search`), payload)
  }

  thirdStepUniversitySearch(payload: any, utils: any): Observable<SearchStep3UniversityModel[]> {
    const {targetGrantsIds, currSelectedOrder, options, is_rural} = utils

    return this.http.post<SearchStep3UniversityModel[]>(this.getUrl(`3/universities/search`), payload)
      .pipe(
        map(universities => {
          const foundGroupWithSpecificTargetGrantAfter = options.slice(currSelectedOrder)
            .find(({university, grant_type}) => (
              SPECIFIC_TARGET_GRANT_UNIVERSITY_IDS.includes(university.id) && targetGrantsIds.includes(grant_type)
            ))

          if (!foundGroupWithSpecificTargetGrantAfter) {
            if (currSelectedOrder === 1) {
              return universities
            } else {
              const editingGroupsBefore = options.slice(0, currSelectedOrder - 1)

              const foundGroupWithSpecificTargetGrantBefore = editingGroupsBefore.find(({university, grant_type}) => (
                SPECIFIC_TARGET_GRANT_UNIVERSITY_IDS.includes(university.id) && targetGrantsIds.includes(grant_type)
              ))

              if (!foundGroupWithSpecificTargetGrantBefore) {
                return universities.filter(university => (
                  !(SPECIFIC_TARGET_GRANT_UNIVERSITY_IDS.includes(university.id) && university.target_probability !== null)
                ))
              } else {
                const specificTargetGrantUniversityId = foundGroupWithSpecificTargetGrantBefore.university.id

                const isAllBeforeGroupsWithSpecificTargetGrants = !editingGroupsBefore
                  .find(({university}) => university.id !== specificTargetGrantUniversityId)

                if (isAllBeforeGroupsWithSpecificTargetGrants) {
                  const otherSpecificTargetGrantUniversityIds = SPECIFIC_TARGET_GRANT_UNIVERSITY_IDS
                    .filter(id => id !== specificTargetGrantUniversityId)

                  return universities.filter(university => (
                    !(otherSpecificTargetGrantUniversityIds.includes(university.id) && university.target_probability !== null)
                  ))
                } else {
                  return universities.filter(university => (
                    !(SPECIFIC_TARGET_GRANT_UNIVERSITY_IDS.includes(university.id) && university.target_probability !== null)
                  ))
                }
              }
            }
          } else {
            const specificTargetGrantUniversityId = foundGroupWithSpecificTargetGrantAfter.university.id

            return universities.filter(university => (
              university.id === specificTargetGrantUniversityId && university.target_probability !== null
            ))
          }
        }),
        map(universities => {
          return universities.map(university => {
            const grantIds = []

            if (university.general_probability !== null) {
              grantIds.push(is_rural ? 3 : 0)
            }

            if (university.target_probability !== null) {
              grantIds.push(is_rural ? 4 : 1)
            }

            if (university.serpin_probability !== null) {
              grantIds.push(is_rural ? 5 : 2)
            }

            university.types = grantIds

            return university
          })
        })
      )
  }
}
