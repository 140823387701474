import {LocaleDataModel} from '@core/models/local/locale.model'

const locale: LocaleDataModel = {
  btn: 'Нашли ошибку?',
  title: 'Выберите тип ошибки',
  description_placeholder: 'Если вашей ошибки нет в списке, напишите подробнее здесь...',
  cancel: 'Отмена',
  submit: 'Отправить',
  success_modal: {
    title: 'Спасибо за помощь!',
    subtitle: 'Обнаружив наши ошибки, вы очень поможете нам в нашем развитии!',
    close: 'Закрыть',
  }
}

export default locale
