import {Component} from '@angular/core'
import {Store} from '@ngxs/store'
import AmplitudeJS from 'amplitude-js'

import {FetchLocalesBasedStatic, Init} from '@core/states/app/actions'
import {isMobileDevice} from '@core/utils/mobile-detect'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  showMobileAppBanner: boolean

  constructor(
    private store: Store
  ) {
    this.showMobileAppBanner = isMobileDevice()

    if (this.showMobileAppBanner) {
      this.store.dispatch(Init)
    } else {
      AmplitudeJS.getInstance().init('811d3d421b59fc77199f37ae25eb79e5')
      this.store.dispatch([Init, FetchLocalesBasedStatic])
    }
  }

  onNavigate(type: 'googleplay' | 'appstore'): void {
    switch (type) {
      case 'appstore':
        window.open('https://apps.apple.com/app/id1497261011', '_blank')
        break
      case 'googleplay':
        window.open('https://play.google.com/store/apps/details?id=com.joo', '_blank')
        break
    }
  }
}
