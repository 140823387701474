import {ProfileModel} from '@core/models/api/core/profile.model'

export class GetProfile {
  static readonly type = '[User] GetProfile'
}

export class UpdateProfile {
  static readonly type = '[User] UpdateProfile'

  constructor(public payload: ProfileModel) {
  }
}

export class UpdateAvatar {
  static readonly type = '[User] UpdateAvatar'

  constructor(public file: File) {
  }
}
