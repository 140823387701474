import {LocaleModel} from '@core/models/local/locale.model'

import general from '@assets/i18n/general/kk'
import notifications from '@assets/i18n/notifications/kk'
import errors from '@assets/i18n/errors/kk'
import auth from '@assets/i18n/auth/kk'
import posts from '@assets/i18n/posts/kk'
import comments from '@assets/i18n/comments/kk'
import sidebar from '@assets/i18n/sidebar/kk'
import universities from '@assets/i18n/universities/kk'
import groups from '@assets/i18n/groups/kk'
import settings from '@assets/i18n/settings/kk'
import edu from '@assets/i18n/edu/kk'
import account_management from '@assets/i18n/account_management/kk'

const localeKk: LocaleModel = {
  lang: 'kk',
  data: {
    kk: 'Қазақша',
    ru: 'Орысша',
    general,
    notifications,
    errors,
    auth,
    posts,
    comments,
    sidebar,
    universities,
    groups,
    settings,
    edu,
    account_management,
  }
}

export default localeKk
