import {LocaleDataModel} from '@core/models/local/locale.model'

const locale: LocaleDataModel = {
  title: 'Тренинг бөлімдері',
  lesson: {
    title: 'Менің сабақтарым',
    page_header: 'Cабақ пәндері',
    detail: 'Онлайн курс материалдары',
    expire: 'Төленбеген пән бар',
  },
  trainer: {
    title: 'Тренажер',
    detail: 'Тесттерді орындау арқылы ҰБТ-ға дайындал'
  },
  ent_questions: {
    title: 'ҰБТ 2022 сұрақтары',
    detail: 'ҰБТ сұрақтарына жауап бер'
  },
  weekly_test: {
    title: 'Апта сайынғы сынақ',
    detail: 'Басқа оқушылармен жарысыңыз'
  },
  trial_test: {
    title: 'Онлайн сынақ тесті',
    start: 'Басталуы',
    end: 'Аяқталуы',
    take_test: 'Тестті тапсыру',
    my_result: 'Менің нәтижем',
  }
}

export default locale
