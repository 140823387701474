import {BrowserModule} from '@angular/platform-browser'
import {LOCALE_ID, NgModule} from '@angular/core'
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common'
import localeRu from '@angular/common/locales/ru'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'

import {CoreModule} from '@core/core.module'
import {TranslateModule} from '@ngx-translate/core'
import {SharedImgModule} from '@shared/components/img/shared-img.module'
import {LangTogglerModule} from '@shared/components/lang-toggler/lang-toggler.module'

registerLocaleData(localeRu, 'ru')

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    TranslateModule,
    SharedImgModule,
    LangTogglerModule,
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: LOCALE_ID, useValue: 'ru'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
