import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'

import {LangTogglerComponent} from './lang-toggler.component'
import {SharedImgModule} from '@shared/components/img/shared-img.module'

@NgModule({
  declarations: [
    LangTogglerComponent,
  ],
  exports: [
    LangTogglerComponent,
  ],
  imports: [
    CommonModule,
    SharedImgModule,
  ],
})
export class LangTogglerModule {
}
