import {LocaleDataModel} from '@core/models/local/locale.model'
import course_purchase from './course-purchase/kk'
import no_course from './no-course/kk'
import lesson from './lesson/kk'
import homework from './homework/kk'
import sections from './sections/kk'
import subjects from './subjects/kk'
import trainer from './trainer/kk'
import weekly_test from './weekly-test/kk'
import trial_test from './trial-test/kk'
import support from './support/kk'

const locale: LocaleDataModel = {
  title: 'Дайындық',
  course_purchase,
  no_course,
  lesson,
  homework,
  sections,
  subjects,
  trainer,
  weekly_test,
  trial_test,
  support
}

export default locale
