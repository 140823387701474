import {LocaleDataModel} from '@core/models/local/locale.model'

const locale: LocaleDataModel = {
  homeworks: 'Үй тапсырмалары',
  show_all: 'Барлығы',
  homework_order: 'Сабақ №{{order}}',
  tasks_cnt: 'Тапсырмалар саны:',
  no_homeworks: 'Үй тапсырмасы жоқ',
  no_homework_questions: 'Бұл уй тапсырмасы бойынша барлық сурақтар дайын емес, біздің команда бұл бағытта жұмыс жасауда.',
  test: {
    title: 'Сынақ парақшасы',
    quit_test: 'Сынақтан шығу',
    question_index: '{{index}} / {{count}}',
    prev_question: 'Алдыңғы сұрақ',
    next_question: 'Келесі сұрақ',
    select_answer: 'Жауапты таңдаңыз',
    finish: 'Сынақты аяқтау',
    submit_ans: 'Жауапты тексеру',
    use_hint: 'Анықтаманы пайдалану',
    hint_no: 'Анықтама #{{no}}',
  },
  messages: {
    test_finished: 'Сынақ аяқталды',
    test_result: 'Құттықтаймыз! Сіз сынақты аяқтадыңыз. Сіздің нәтижеңіз - {{points}}/{{total}}',
    test_not_available: 'Үй тапсырмасы әлі қол жетімді емес',
    test_available_time: 'Үй тапсырмасы {{time}} басталады'
  }
}

export default locale
